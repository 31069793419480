import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";

import Arrows from "./Arrows";

//image big view.
export default function Modal(props) {
  const [modalImg, setModalImg] = useState(props.modalImg);
  const [images, setImages] = useState(props.images);
  const [modalImgSrc, setModalImgSrc] = useState(props.modalImgSrc);
  const [modalIn, setModalIn] = useState(false);
  // console.log(images);
  useEffect(() => {
    props.showModal && setModalIn(true);
  }, [props.showModal]);

  const handleCloseModal = () => {
    setModalIn(false);

    const closeTimer = setTimeout(() => {
      props.closeModal();
    }, 500);

    return () => {
      clearTimeout(closeTimer);
    };
  };

  return (
    <>
      <CSSTransition
        in={modalIn}
        timeout={1000}
        classNames="modal-bg"
        // unmountOnExit
      >
        <div className="modal">
          <i className="modal-close fas fa-times" onClick={handleCloseModal} />
          <Arrows
            leftArrowClick={() => {
              console.log(images[modalImg].img);
              setModalImg(Math.max(modalImg - 1, 1));
              setModalImgSrc(images[modalImg].img);
            }}
            rightArrowClick={() => {
              if (modalImg >= 1 && modalImg <= 9) {
                console.log(images[modalImg].img);
                setModalImg(Math.min(modalImg + 1, 1));
                setModalImgSrc(images[modalImg].img);
              } else {
                setModalImg(1);
              }
            }}
          />
        </div>
      </CSSTransition>

      <CSSTransition
        in={modalIn}
        timeout={1000}
        classNames="modal-img"
        // unmountOnExit
      >
        <div className="modal-img">
          <img src={`${modalImgSrc}`} alt="" onClick={handleCloseModal} />
        </div>
      </CSSTransition>
    </>
  );
}
