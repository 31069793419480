import React, { useState, useEffect } from "react";
import ReactImageAppear from "react-image-appear";

import Modal from "./Modal";
import Arrows from "./Arrows";
import { Storage } from "aws-amplify";
import ImageListItem from "@mui/material/ImageListItem";
import ImageList from "@mui/material/ImageList";
import ImageListItemBar from "@mui/material/ImageListItemBar";

function getImages() {
  let images = [];
  for (let i = 1; i < 10; i++) {
    Storage.get(`${i}.jpg`).then((response) => {
      images.push({
        img: response,
        title: "Fermie",
        author: "@claude__chen",
        index: i,
      });
    });
  }
  return images;
}

export default function Work() {
  const [modalImg, setModalImg] = useState();
  const [modalImgSrc, setModalImgSrc] = useState();
  const [showModal, setShowModal] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [images, setImages] = useState(getImages());

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  });

  let wrapper;

  const scrollWrapperRight = () => {
    wrapper.scrollBy({ left: 600, behavior: "smooth" });
  };

  const scrollWrapperLeft = () => {
    wrapper.scrollBy({ left: -600, behavior: "smooth" });
  };

  const handleScrollWheel = (e) => {
    if (windowWidth > 1024 && !showModal) {
      e.preventDefault();
      const wrapperScrollPosition = wrapper.scrollLeft;
      wrapper.scrollTo({
        top: 0,
        left: wrapperScrollPosition + e.deltaY,
        behaviour: "smooth",
      });
    }
  };

  const handleOpenModal = (i, imgSrc) => {
    setModalImg(i);
    setModalImgSrc(imgSrc);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalImg();
    setModalImgSrc();
  };

  return (
    <div
      className="scrolling-wrapper disable-scrollbars"
      ref={(node) => (wrapper = node)}
      onWheel={(e) => handleScrollWheel(e)}
    >
      <ImageList sx={{ width: 1000, height: 550 }}>
        {images.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}`}
              srcSet={`${item.img}`}
              alt={item.title}
              loading="lazy"
              onClick={() => handleOpenModal(item.index, item.img)}
            />
            {/* <ReactImageAppear
              key={item.index}
              src={`${item.img}`}
              className="scrolling-img"
              alt={`Image ${item.index}`}
              onClick={() => handleOpenModal(item.index, item.img)}
            /> */}
            <ImageListItemBar title={item.title} position="bottom" />
          </ImageListItem>
        ))}
      </ImageList>

      <div className="mobile-copyright">
        <i className="far fa-copyright" /> CLAUDECHEN 2022
      </div>

      <Arrows
        leftArrowClick={scrollWrapperLeft}
        rightArrowClick={scrollWrapperRight}
      />

      {showModal && (
        <Modal
          modalImg={modalImg}
          modalImgSrc={modalImgSrc}
          images={images}
          closeModal={handleCloseModal}
          showModal={showModal}
        />
      )}
    </div>
  );
}
